import { ElementStyle } from "../styles/element-styles";

export type NeedleDef = {
    min: number,
    max: number,
    value: number,
    radius: number,
    thickness: number
}

export const Needle = (needleDef: NeedleDef, style: ElementStyle) => {
    const rotation = ((needleDef.value - needleDef.min)/(needleDef.max - needleDef.min)) * 360 - 90;
    return `<rect transform="rotate(${rotation})" fill="${style.fill}"  stroke="${style.stroke}" x="0" y="0" width="${needleDef.radius}" height="${needleDef.thickness}"></rect>`;
}

import React, { useState } from 'react';
import { Stack, TextField } from '@mui/material';
import ReactDOM from 'react-dom';
import { Circle } from '../../elements/circle';
import { ElementStyle, ElementStyles } from '../../styles/element-styles';
import { Arc, ArcDef } from '../../elements/arc';
import { Needle, NeedleDef } from '../../elements/needle';
import { SvgBox } from '../../elements/svg_box';
import { roundDown, sequence } from '../../util/numbers';
import { yearToMs } from '../../util/calendar';

type LifeClockProps = {dob: Date, durationYears: number};

export const LifeClock: React.FC<LifeClockProps> = (props: LifeClockProps) => {

    const circleRadius = 300;
    const YearMS = 365.25 * 24 * 60 * 60 * 1000;
    const expectancy = 85;

    const createArc = (min: number, max: number, startValue: number, endValue: number, radius: number, thickness: number, label: string, style: ElementStyle) => {
        const arcDef = {
            min: min,
            max: max,
            startValue: startValue,
            endValue: endValue,
            radius: radius,
            thickness: thickness,
            label: label
        } as ArcDef;
        return Arc(arcDef, style);    
    }

    const birthYear = props.dob.getFullYear();
    const deathYear = props.dob.getFullYear() + props.durationYears;
    const startMs = props.dob.getTime();
    const endMs = new Date(deathYear, props.dob.getMonth(), props.dob.getDate()).getTime();
    const deathDate = new Date(props.dob.getFullYear() + props.durationYears, props.dob.getMonth(), props.dob.getDate());
    const years = sequence(birthYear, props.durationYears);
    const decades = Array.from(new Set(years.map(y => roundDown(y, 10)))).sort();

    const ages = sequence(1, props.durationYears);
    const ageDecades = Array.from(new Set(ages.map(y => roundDown(y, 10)))).sort();

    const decadeArcs = decades.map(y => 
        createArc(startMs, endMs, 
            Math.max(startMs, yearToMs(y)), 
            Math.min(endMs, yearToMs(y+10)), 
            circleRadius - 50, expectancy, y.toString() + 's', ElementStyles.year)).join();

    const ageDecadeArcs = ageDecades.map(y => 
        createArc(startMs, endMs, 
            Math.max(startMs, new Date(birthYear + y, props.dob.getMonth(), props.dob.getDate()).getTime()), 
            Math.min(endMs, new Date(birthYear + y + 10, props.dob.getMonth(), props.dob.getDate()).getTime()), 
            circleRadius-135, expectancy, y.toString() + 's', ElementStyles.age)).join();

    const needleDef = {min: startMs, max: deathDate.getTime(), value: new Date().getTime(), radius: circleRadius, thickness: circleRadius/30 } as NeedleDef;
    const needleSvg = Needle(needleDef, ElementStyles.now);
    const circleSvg = Circle({radius: circleRadius, thickness: circleRadius/20});


    const children = '<g>' + circleSvg + decadeArcs  + ageDecadeArcs + needleSvg + '</g>';
    const svgBox = SvgBox(320, 320, 320, 320, children);

return <Stack direction="row" width="100%">
        <img src={"data:image/svg+xml;utf8,"+svgBox} width={circleRadius} height={circleRadius}/>
    </Stack>
};

export type ElementStyle = {
    fill?: string;
    stroke?: string;
    fontSize?: string;
    textFill?: string
}


export const ElementStyles = 
{
    year: {
    fill: 'lightblue',
    textFill: 'navy',
    stroke: 'navy',
    fontSize: '32'
    } as ElementStyle,

    age: {
        fill: 'rgb(220,245,230)',
        textFill: 'navy',
        stroke: 'navy',
        fontSize: '32'
        } as ElementStyle,

    now: {
        fill: 'cyan',
        textFill: 'navy',
        stroke: 'navy',
        fontSize: '32'
        } as ElementStyle
}
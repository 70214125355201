import React, { useState } from 'react';
import { Stack, TextField, Tabs, Tab } from '@mui/material';
import ReactDOM from 'react-dom';
import { LifeClock } from './life-clock';
import { DecadeClock } from './lifedecade-clock';

type LifeClockProps = {dob: Date, durationYears: number};

export const Clocks: React.FC<LifeClockProps> = (props: LifeClockProps) => {

    const [tab, setTab] = React.useState("life")

    return <Stack direction="column">
        {tab == "life" && <LifeClock dob={props.dob} durationYears={props.durationYears} />}
        {tab == "decade" && <DecadeClock dob={props.dob} durationYears={props.durationYears} />}
        <Tabs value={tab} onChange={(event: React.SyntheticEvent, newValue: string) => setTab(newValue)} aria-label="basic tabs example">
            <Tab label="Life" value="life" />
            <Tab label="Decade" value="decade" />
            {/* <Tab label="Year" value="year" /> */}
        </Tabs>
    </Stack>;
}    

const root = ReactDOM.render(<Clocks dob={new Date(1971, 7, 2)} durationYears={85}/>, document.getElementById('root'));

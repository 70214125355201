export type XY = {
    x: number,
    y: number
}

export const polarToCartesian = (radius: number, angleInRadians: number) => {
    return {
      x: (radius * Math.cos(angleInRadians)),
      y: (radius * Math.sin(angleInRadians))
    } as XY;
  }

export const valueToCoord = (min: number, max: number, value: number, radius: number) => {
    return polarToCartesian(radius, valueToRotation(min, max, value));
}

export const valueToRotation = (min: number, max: number, value: number) => {
    const fraction = (value-min)/(max-min);
    return (fraction * 2 * Math.PI);// + Math.PI/2;
}

import { polarToCartesian, valueToRotation } from "../util/geometry"

export type CircleDef = {
    radius: number,
    thickness: number,
}
export const Circle = (circleDef: CircleDef) => {
    return `<circle stroke="black" fill="lightgrey" stroke-width="${circleDef.thickness}" cx="0" cy="0" r="${circleDef.radius}" />`;
} 
 

import { ElementStyle } from "../styles/element-styles";
import { polarToCartesian, valueToRotation } from "../util/geometry";
import * as d3 from "d3";

export type ArcDef = {
    min: number,
    max: number,
    startValue: number,
    endValue: number,
    radius: number,
    thickness: number,
    label: string
}

const randomId = () => {
    return Math.random().toString(36).slice(2, 7);
}

export const Arc = (arcDef: ArcDef, style: ElementStyle) => {
    const startAngle = valueToRotation(arcDef.min, arcDef.max, arcDef.startValue);
    const endAngle = valueToRotation(arcDef.min, arcDef.max, arcDef.endValue);
    const angleSweep = endAngle-startAngle;
    const largeArcFlag = angleSweep > Math.PI ? 1 : 0;
    const startCoord = polarToCartesian(arcDef.radius, 0);
    const endCoord = polarToCartesian(arcDef.radius, (endAngle-startAngle));

    var arc = d3.arc()
       .cornerRadius(arcDef.thickness/20)
       .innerRadius(arcDef.radius - (arcDef.thickness/2))
       .outerRadius(arcDef.radius + (arcDef.thickness/2))
       .startAngle(startAngle)
       .endAngle(endAngle);
    const textPath = `M ${startCoord.x} ${startCoord.y} ` + 
        `A ${arcDef.radius} ${arcDef.radius} ` + 
        `0 ${largeArcFlag} 1 ${endCoord.x} ${endCoord.y}`;

    //@ts-ignore
    const arcPath = arc();
    const pathId = randomId();

    let result = `<g fill="${style.fill}" stroke="${style.stroke}" >` + 
        `<path stroke="${style.stroke}" fill="${style.fill}"  d="${arcPath}" />`;
        if ( (endAngle-startAngle) > Math.PI /10 ) {
            result += `<defs><path stroke="${style.fill}" id="${pathId}" d="${textPath}" /></defs>` + 
                    `<text transform="rotate(${startAngle * 180 / Math.PI - 90})" stroke="none" fill="${style.textFill}"><textPath text-anchor="middle" alignment-baseline="middle" startOffset="50%" font-size="${style.fontSize}" href='%23${pathId}'>${arcDef.label}</textPath></text>`;
        }
        result += '</g>';
    return result;
}

import React, { useState } from 'react';
import { Stack, TextField } from '@mui/material';
import ReactDOM from 'react-dom';
import { Circle } from '../../elements/circle';
import { ElementStyle, ElementStyles } from '../../styles/element-styles';
import { Arc, ArcDef } from '../../elements/arc';
import { Needle, NeedleDef } from '../../elements/needle';
import { SvgBox } from '../../elements/svg_box';
import { roundDown, sequence } from '../../util/numbers';
import { yearToMs } from '../../util/calendar';

type LifeClockProps = {dob: Date, durationYears: number};

export const DecadeClock: React.FC<LifeClockProps> = (props: LifeClockProps) => {

    const circleRadius = 300;
    const YearMS = 365.25 * 24 * 60 * 60 * 1000;

    const createArc = (min: number, max: number, startValue: number, endValue: number, radius: number, thickness: number, label: string, style: ElementStyle) => {
        const arcDef = {
            min: min,
            max: max,
            startValue: startValue,
            endValue: endValue,
            radius: radius,
            thickness: thickness,
            label: label
        } as ArcDef;
        return Arc(arcDef, style);    
    }

    const now = new Date();

    const currentAge = (now.getTime() - props.dob.getTime()) / YearMS;
    const currentDecade = Math.floor(currentAge/10) * 10;

    const ages = sequence(currentDecade, 10);
    console.log(ages);
    const years = sequence(props.dob.getFullYear() + currentDecade, 11);

    const startMs = new Date(props.dob.getFullYear() + currentDecade, props.dob.getMonth(), props.dob.getDate()).getTime();
    const endMs = new Date(props.dob.getFullYear() + currentDecade + 10, props.dob.getMonth(), props.dob.getDate()).getTime();

    const birthYear = props.dob.getFullYear();
    const deathDate = new Date(props.dob.getFullYear() + props.durationYears, props.dob.getMonth(), props.dob.getDate());

    const ageArcs = ages.map(y => 
        createArc(startMs, endMs, 
            Math.max(startMs, new Date(props.dob.getFullYear() + y, props.dob.getMonth(), props.dob.getDate()).getTime()), 
            Math.min(endMs, new Date(props.dob.getFullYear() + y + 1, props.dob.getMonth(), props.dob.getDate()).getTime()), 
            circleRadius - 135, 80, y.toString(), ElementStyles.age)).join();

            console.log(ageArcs);
    const yearArcs = years.map(y => 
        createArc(startMs, endMs, 
            Math.max(startMs, yearToMs(y)), 
            Math.min(endMs, yearToMs(y+1)), 
            circleRadius-50, 80, y.toString(), ElementStyles.year)).join();

    const needleDef = {min: startMs, max: endMs, value: new Date().getTime(), radius: circleRadius, thickness: circleRadius/30 } as NeedleDef;
    const needleSvg = Needle(needleDef, ElementStyles.now);
    const circleSvg = Circle({radius: circleRadius, thickness: circleRadius/20});


    const children = '<g>' + circleSvg + ageArcs  + yearArcs + needleSvg + '</g>';
    const svgBox = SvgBox(320, 320, 320, 320, children);

return <Stack direction="row" width="100%">
        <img src={"data:image/svg+xml;utf8,"+svgBox} width={circleRadius} height={circleRadius}/>
    </Stack>
};
